.service_pay {
    margin-top: 11px;
    .title {
        text-align: center;
        h1 {
            font-size: 20px;
            color: #F24259;
            font-weight: 600;
            line-height: 30px;
            font-style: normal;
            text-transform: uppercase;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
    }
    .service_pay__name {
        padding: 0 20px;
        margin-top: 20px;
        text-align: center;
        h2 {
            color: #000;
            font-size: 20px;
            font-weight: 500;
            line-height: 25px;
            font-style: normal;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
    }
    .service_pay__price {
        display: flex;
        padding: 0 20px;
        margin-top: 10px;
        align-items: center;
        justify-content: space-between;
        p {
            font-size: 17px;
            font-weight: 500;
            line-height: 25px;
            font-style: normal;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
            &:first-child {
                color: #000;
                font-weight: 450;
            }
            &:last-child {
                color: #F24259;
            }   
        }
    }
    .service_pay__promocode {
        padding: 0 20px;
        margin-top: 8px;
        p {
            font-size: 14px;
            line-height: 25px;
            font-style: normal;
            font-optical-sizing: auto;
            font-family: "Inter", sans-serif;
            font-variation-settings: "slnt" 0;
        }
        form {
            .service_pay__promocode__block {
                gap: 10px;
                padding: 4px;
                display: flex;
                margin-top: 4px;
                border-radius: 8px;
                justify-content: center;
                border: 1px solid #86C9FF;
                input {
                    width: 100%;
                }
                button {
                    width: 85px;
                    height: 40px;
                    color: #fff;
                    padding: 0 5px;
                    border-radius: 8px;
                    background: #35a3fb;
                }
            }
            .service_pay__email_block {
                gap: 10px;
                padding: 4px;
                display: flex;
                margin-top: 4px;
                border-radius: 8px;
                justify-content: center;
                border: 1px solid #86C9FF;
                input {
                    width: 100%;
                    height: 40px;
                }
            }
        }
        .email_title {
            margin-top: 10px;
            gap: 5px;
            display: flex;
            align-items: center;
        }
    }
    .service_pay__btn {
        margin-top: 28px;
        text-align: center;
        button {
            color: #fff;
            font-weight: 600;
            padding: 15px 60px;
            border-radius: 8px;
            background-color: #F24259;
        }
    }
}